import request from "../request";
export default {
  // 积分商品详情
  pointsProductDetails(data) {
    return request({
      url: "/shop/pointsProductDetails",
      method: "post",
      data
    });
  },

  // 积分商品
  pointsProducts(params) {
    return request({
      url: "/shop/pointsProducts",
      method: "get",
      params
    });
  },

  // 积分商品
  goodsCate(params) {
    return request({
      url: "/shop/goodsCate",
      method: "get",
      params
    });
  },

  // 商城首页
  mall(params) {
    return request({
      url: "/shop/mall",
      method: "get",
      params
    });
  },

  // 我的收货地址
  myAddresses(params) {
    return request({
      url: "/shop/myAddresses",
      method: "get",
      params
    });
  },

  // 增加收货地址
  addAddress(data) {
    return request({
      url: "/shop/addAddress",
      method: "post",
      data
    });
  },

  // 更改收货地址
  editAddress(data) {
    return request({
      url: "/shop/editAddress",
      method: "post",
      data
    });
  },

  // 删除收货地址
  delAddress(data) {
    return request({
      url: "/shop/delAddress",
      method: "post",
      data
    });
  },

  // 积分订单确认
  pointsOrderConfirmation(data) {
    return request({
      url: "shop/pointsOrderConfirmation",
      method: "post",
      data
    });
  },

  // 积分订单提交
  pointsOrderSubmission(data) {
    return request({
      url: "shop/pointsOrderSubmission",
      method: "post",
      data
    });
  },

  // 积分订单列表
  pointsOrderList(data) {
    return request({
      url: "shop/pointsOrderList",
      method: "post",
      data
    });
  },

  // 积分订单详情
  pointsOrderDetails(data) {
    return request({
      url: "shop/pointsOrderDetails",
      method: "post",
      data
    });
  },

  // 积分订单确认操作
  pointsOrderOperate(data) {
    return request({
      url: "/shop/pointsOrderOperate",
      method: "post",
      data
    });
  },

  // 表情兑换
  emojiExchange(data) {
    return request({
      url: "shop/emojiExchange",
      method: "post",
      data
    });
  },

};
