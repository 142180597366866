import Vue from 'vue'
import 'mutationobserver-shim'
import './Utils/filter'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
// import './registerServiceWorker'
import i18n from './i18n'
import { getlocalStorage, setlocalStorage, remlocalStorage } from './Utils/localStorage'
import VueClipboard from 'vue-clipboard2'

import VueCropper from 'vue-cropper'
import http from './http/api'

import VueAMap from 'vue-amap'

import Fragment from 'vue-fragment'

import Base64 from 'js-base64'

import vueQr from 'vue-qr'

import Bus from '@/Utils/Bus'

// 挂载Swiper   == > 暂未使用
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(Fragment.Plugin)
Vue.use(Base64)
Vue.component('vueQr', vueQr)
Vue.prototype.$bus = Bus
Vue.use(VueClipboard)
Vue.use(VueCropper)
Vue.use(VueAMap)
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'AIzaSyDYAjJ-y5cRPvO92XMWrJGmyFZ-hwxjR2w',
    libraries: 'places', // This is required if you use the Auto complete plug-in
    language: 'en'
  }
})
Vue.use(VueAwesomeSwiper)
Vue.prototype.$getlocalStorage = getlocalStorage
Vue.prototype.$setlocalStorage = setlocalStorage
Vue.prototype.$remlocalStorage = remlocalStorage
Vue.use(http)
Vue.use(BootstrapVueIcons)
// 防止连续点击按钮
Vue.directive("preventRe", {
  bind(el, binding){
    let timer;
    el.addEventListener("click", () => {
      if (!el.disabled) {
        el.disabled = true;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          el.disabled = false;
        }, binding.value || 3000);
      }
    });
  },
});
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations()
    .then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister()
      }
    })
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister()
  })
}

Date.prototype.Format = function (fmt) { // author: meizz
  var o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    'S': this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (var k in o) { if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  return fmt
}

global.Raphael = Raphael
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})
Vue.config.productionTip = false

// 表头文字重新渲染共用方法
Vue.prototype.$renderHeader = (h, { column, $index }) => {
  // 新建一个 span
  let span = document.createElement('span')
  // 设置表头名称
  span.innerText = column.label
  // 临时插入 document
  document.body.appendChild(span)
  // 重点：获取 span 最小宽度，设置当前列，注意这里加了 20，字段较多时还是有挤压，且渲染后的 div 内左右 padding 都是 10，所以 +20 。（可能还有边距/边框等值，需要根据实际情况加上）
  let minWidth = span.getBoundingClientRect().width + 20
  column.minWidth = minWidth > 150 ? minWidth : 150
  // 移除 document 中临时的 span
  document.body.removeChild(span)
  return h('span', column.label)
}

let vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
window.payStatus = ''
/**
 *
 *
 * */
