import request from '../request'
export default {
  // 支付成功改变状态
  eventPayPalCheckoutSuccess(data) {
    return request({
      url: "/api/eventPayPalCheckoutSuccess",
      method: "POST",
      data
    });
  },
  // 获取活动列表
  listSalon (data) {
    return request({
      url: '/api/listSalon',
      method: 'POST',
      data
    })
  },
  // 活动详情页
  detailsSalon (data) {
    return request({
      url: '/api/detailsSalon',
      method: 'POST',
      data
    })
  },
  // 获取活动评论
  salonCommentList (data) {
    return request({
      url: '/api/salonCommentList',
      method: 'POST',
      data
    })
  },
  // 提交活动评论
  postCommentSalon (data) {
    return request({
      url: '/api/postCommentSalon',
      method: 'POST',
      data
    })
  },
  // 购买活动写入活动订单
  writeOrderSalon (data) {
    return request({
      url: '/api/writeOrderSalon',
      method: 'POST',
      data
    })
  },
  // 获取日历-某一天我参与的相关行程列表
  listCalendar (data) {
    return request({
      url: '/api/listCalendar',
      method: 'POST',
      data
    })
  },
  // 获取COC公司成员
  getCorporator (data) {
    return request({
      url: '/api/getCorporator',
      method: 'POST',
      data
    })
  },
  // 获取JCI董事会成员
  getDirector (data) {
    return request({
      url: '/api/getDirector',
      method: 'POST',
      data
    })
  },
  // 获取赞助商和合作伙伴
  getPartner (data) {
    return request({
      url: '/api/getPartner',
      method: 'POST',
      data
    })
  },
  // 回复活动评论
  replyCommentSalon (data) {
    return request({
      url: '/api/replyCommentSalon',
      method: 'POST',
      data
    })
  },
  // 点赞
  salonLikeComment (data) {
    return request({
      url: '/api/salonLikeComment',
      method: 'POST',
      data
    })
  },
  // 取消点赞
  salonDeleteLike (data) {
    return request({
      url: '/api/salonDeleteLike',
      method: 'POST',
      data
    })
  },
  // 删除回复评论
  salonDeleteComment (data) {
    return request({
      url: '/api/salonDeleteComment',
      method: 'POST',
      data
    })
  },
  // 获取我参加的活动列表
  mySalon (data) {
    return request({
      url: '/api/mySalon',
      method: 'POST',
      data
    })
  },
  // 获取我参加的活动列表
  getAttendees (data) {
    return request({
      url: '/api/getAttendees',
      method: 'POST',
      data
    })
  },
  // 获取我的活动行程
  mySchedulesSimple (data) {
    return request({
      url: '/api/mySchedulesSimple',
      method: 'POST',
      data
    })
  },
  // 获取活动套餐详情
  getSalonSetmealsDetails (data) {
    return request({
      url: '/api/getSalonSetmealsDetails',
      method: 'POST',
      data
    })
  },
  // 添加子进程到我的活动行程
  addSchedules (data) {
    return request({
      url: '/api/addSchedules',
      method: 'POST',
      data
    })
  },
  // 活动参与者是否展示设置
  attendeesSalon (data) {
    return request({
      url: '/api/attendeesSalon',
      method: 'POST',
      data
    })
  },
  // 正在进行的活动
  getLiveNow (data) {
    return request({
      url: '/api/getLiveNow',
      method: 'POST',
      data
    })
  },
  // 正在进行的活动
  getSetmeal (data) {
    return request({
      url: '/api/getSetmeal',
      method: 'POST',
      data
    })
  },
  // 正在进行的活动
  getSalonMedia (data) {
    return request({
      url: '/api/getSalonMedia',
      method: 'POST',
      data
    })
  },
  // 获取用户的可见度
  salonUserShow (data) {
    return request({
      url: '/api/salonUserShow',
      method: 'POST',
      data
    })
  },
  // 管理团队或BOD团队中的好友列表
  salonCocFriendList (data) {
    return request({
      url: '/api/salonCocFriendList',
      method: 'POST',
      data
    })
  },
  // 活动所有的行程
  getSubproces (data) {
    return request({
      url: '/api/getSubproces',
      method: 'POST',
      data
    })
  },
  // 评论信息
  salonComment (data) {
    return request({
      url: '/api/salonComment',
      method: 'POST',
      data
    })
  },
  // 删除回复
  delReplyCommentSalon (data) {
    return request({
      url: '/api/delReplyCommentSalon',
      method: 'POST',
      data
    })
  },
  // 获取参与者中的好友列表
  salonAttendeeFriend (data) {
    return request({
      url: '/api/salonAttendeeFriend',
      method: 'POST',
      data
    })
  },
  // 活动所有的行程
  getSubproces (data) {
    return request({
      url: '/api/getSubproces',
      method: 'POST',
      data
    })
  },
  // 某个套餐的所有行程
  getSetmealSubproces (data) {
    return request({
      url: '/api/getSetmealSubproces',
      method: 'POST',
      data
    })
  },
  // 获取活动套餐
  getSetmeals (data) {
    return request({
      url: '/api/getSetmeals',
      method: 'POST',
      data
    })
  },
  // 参加活动
  registerSalon (data) {
    return request({
      url: '/api/registerSalon',
      method: 'POST',
      data
    })
  },
  // 用户套餐的所有行程
  getMyEvent (data) {
    return request({
      url: '/api/getMyEvent',
      method: 'POST',
      data
    })
  },
  // 我的行程
  mySchedules (data) {
    return request({
      url: '/api/mySchedules',
      method: 'POST',
      data
    })
  },
  // 某个套餐的信息
  getSetmealInfo (data) {
    return request({
      url: '/api/getSetmealInfo',
      method: 'POST',
      data
    })
  },
  // 某个套餐的信息
  eventDeleteProgram (data) {
    return request({
      url: '/api/eventDeleteProgram',
      method: 'POST',
      data
    })
  },
  // 生成订单
  eventPayPackage (data) {
    return request({
      url: '/api/eventPayPackage',
      method: 'POST',
      data
    })
  },
  // 信用卡支付
  eventRecharge (data) {
    return request({
      url: '/api/eventRecharge',
      method: 'POST',
      data
    })
  },
  // 轮询
  eventPolling (data) {
    return request({
      url: '/api/eventPolling',
      method: 'POST',
      data
    })
  },

  // 问卷详情
  eventQuestionnaireContent (data) {
    return request({
      url: '/api/eventQuestionnaireContent',
      method: 'POST',
      data
    })
  },
  // 问卷提交
  eventQuestionnaireSubmit (data) {
    return request({
      url: '/api/eventQuestionnaireSubmit',
      method: 'POST',
      data
    })
  },
  eventAddProgram (data) {
    return request({
      url: '/api/eventAddProgram',
      method: 'POST',
      data
    })
  },

  // 订单
  eventOrderList (params) {
    return request({
      url: '/api/eventOrderList',
      method: 'GET',
      params
    })
  },
  eventOrderDelete (data) {
    return request({
      url: '/api/eventOrderDelete',
      method: 'POST',
      data
    })
  },
  eventOrderDetail (params) {
    return request({
      url: '/api/eventOrderDetail',
      method: 'GET',
      params
    })
  },
  getOrderLogList (params) {
    return request({
      url: '/api/getOrderLogList',
      method: 'GET',
      params
    })
  },
  getEventProgramList (params) {
    return request({
      url: '/api/getEventProgramList',
      method: 'GET',
      params
    })
  },
  eventGiftUsers (data) {
    return request({
      url: '/api/eventGiftUsers',
      method: 'POST',
      data
    })
  },
  searchUserList (params) {
    return request({
      url: '/api/searchUserList',
      method: 'GET',
      params
    })
  },
  orderRemark (data) {
    return request({
      url: '/api/orderRemark',
      method: 'POST',
      data
    })
  },
  eventDeleteGiftUsers (data) {
    return request({
      url: '/api/eventDeleteGiftUsers',
      method: 'POST',
      data
    })
  },
  eventOrderCancel (data) {
    return request({
      url: '/api/eventOrderCancel',
      method: 'POST',
      data
    })
  },
  eventRefundApply (data) {
    return request({
      url: '/api/eventRefundApply',
      method: 'POST',
      data
    })
  },

  // 判斷是否有权利点击链接
  eventUserHasPowerClickLink (data) {
    return request({
      url: '/api/eventUserHasPowerClickLink',
      method: 'POST',
      data
    })
  },
}