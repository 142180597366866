import JSEncrypt from 'jsencrypt'

// 获取密钥对
async function getRsaKeys () {
  const key = await window.crypto.subtle.generateKey(
    {
      name: 'RSA-OAEP',
      modulusLength: 2048, // can be 1024, 2048, or 4096
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: { name: 'SHA-256' } // can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
    },
    true, // whether the key is extractable (i.e. can be used in exportKey)
    ['encrypt', 'decrypt'] // must be ["encrypt", "decrypt"] or ["wrapKey", "unwrapKey"]
  )

  const keydata1 = await window.crypto.subtle.exportKey(
    'pkcs8',
    key.privateKey
  )

  const keydata2 = await window.crypto.subtle.exportKey(
    'spki',
    key.publicKey
  )
  // 公钥
  var publicKey = RSA2text(keydata2)
  //私钥
  var privateKey = RSA2text(keydata1, true)
  return { privateKey, publicKey }
}
// 利用生成的密钥生成公私钥
function RSA2text (buffer, isPrivate) {
  var binary = ''
  var bytes = new Uint8Array(buffer)
  var len = bytes.byteLength
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  var base64 = window.btoa(binary)
  // console.log("base64",base64)
  // var text = '-----BEGIN ' + (isPrivate ? 'PRIVATE' : 'PUBLIC') + ' KEY-----\n'
  // text += base64.replace(/[^\x00-\xff]/g, '$&\x01').replace(/.{64}\x01?/g, '$&\n')
  // text += '-----END ' + (isPrivate ? 'PRIVATE' : 'PUBLIC') + ' KEY-----'
  // console.log(text)
  return base64
}
// 加密方法
function RSADecryptRSA (pubKey, data) {
  // 格式化公钥
  // pubKey = pubKey.replace(/\n/g, '')
  // pubKey = pubKey.replace('-----BEGIN PUBLIC KEY-----', '')
  // pubKey = pubKey.replace('-----END PUBLIC KEY-----', '')
  // const num = Math.ceil(pubKey.length / 64)
  // let str = ''
  // for (let index = 1; index <= num; index++) {
  //   str += pubKey.slice((index - 1) * 64, index * 64) + '\n'
  // }
  // pubKey = '-----BEGIN PUBLIC KEY-----\n' + str + '-----END PUBLIC KEY-----'
  // console.log('pubKey', pubKey)
  const encryptor = new JSEncrypt() // 创建加密对象实例

  encryptor.setPublicKey(pubKey)// 设置公钥
  const rsaPassWord = encryptor.encrypt(data) // 对内容进行加密
  // console.log('rsaPassWord', rsaPassWord)
  return rsaPassWord
}
// 解密方法
function RSAEncrypt (priKey, data) {
  const decrypt = new JSEncrypt()// 创建解密对象实例
  decrypt.setPrivateKey(priKey)// 设置秘钥
  const uncrypted = decrypt.decrypt(data)// 解密之前拿公钥加密的
  return uncrypted
}
export { getRsaKeys, RSADecryptRSA, RSAEncrypt }
