import request from '../request'
export default {
  // 支付成功改变状态
  projectPayPalCheckoutSuccess(data) {
    return request({
      url: "/api/projectPayPalCheckoutSuccess",
      method: "POST",
      data
    });
  },
  // 项目列表
  projectList (data) {
    return request({
      url: '/api/projectList',
      method: 'POST',
      data
    })
  },
  // 项目详情
  projectDetail (data) {
    return request({
      url: '/api/projectDetail',
      method: 'POST',
      data
    })
  },
  // 选项卡切换
  projectTab (data) {
    return request({
      url: '/api/projectTab',
      method: 'POST',
      data
    })
  },

  // 添加评论
  projectAddComment (data) {
    return request({
      url: '/api/projectAddComment',
      method: 'POST',
      data
    })
  },

  // 删除评论
  projectDeleteComment (data) {
    return request({
      url: '/api/projectDeleteComment',
      method: 'POST',
      data
    })
  },

  // 点击Register参加
  projectAttend (data) {
    return request({
      url: '/api/projectAttend',
      method: 'POST',
      data
    })
  },


  // 问卷详情
  questionnaireContent (data) {
    return request({
      url: '/api/questionnaireContent',
      method: 'POST',
      data
    })
  },
  questionnaireSubmit (data) {
    return request({
      url: '/api/questionnaireSubmit',
      method: 'POST',
      data
    })
  },
  packageList (data) {
    return request({
      url: '/api/packageList',
      method: 'POST',
      data
    })
  },

  // 套餐列表
  packageList (data) {
    return request({
      url: '/api/packageList',
      method: 'POST',
      data
    })
  },

  // 項目流程
  projectProgram (data) {
    return request({
      url: '/api/projectProgram',
      method: 'POST',
      data
    })
  },

  // 添加行程到日程表
  projectAddProgram (data) {
    return request({
      url: '/api/projectAddProgram',
      method: 'POST',
      data
    })
  },

  // 取消行程到日程表
  projectDeleteProgram (data) {
    return request({
      url: '/api/projectDeleteProgram',
      method: 'POST',
      data
    })
  },


  // 支付
  payPackage (data) {
    return request({
      url: '/api/payPackage',
      method: 'POST',
      data
    })
  },
  userHasPowerClickLink (data) {
    return request({
      url: '/api/userHasPowerClickLink',
      method: 'POST',
      data
    })
  },



}
