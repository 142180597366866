export default {
  // 获取国家 or 地区
  async getAddress ({ commit }, data) {
    let res = await vm.$http.getAddress(data)
    if (res.status == 200) {
      if (data.level === 2) {
        commit("GET_COUNTRY_LIST", res.data)
      } else {
        !res.data.length ? commit("CHANGE_IS_SHOW", false) : commit("CHANGE_IS_SHOW", true)
        commit("GET_REGION_LIST", res.data)
      }
    }
  },

  // 获取个人信息
  async getUserInfo ({ commit }) {
    let {status,data} = await vm.$http.getUserInfo();
    if (status==200) commit("CHANGE_USER_INFO", data.user_info)
  },


  // 获取当前语言
  async getLangData ({ commit }, params) {
    let { status, data } = await vm.$http.getWebLanguageData({
      type: 'en'
    })
  }

}
