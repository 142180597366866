import request from "../request";
export default {
  eventList(data) {
    return request({
      url: "/api/eventList",
      method: "post",
      data
    });
  },
  eventDetail(data) {
    return request({
      url: "/api/eventDetail",
      method: "post",
      data
    });
  },
  eventTab(data) {
    return request({
      url: "/api/eventTab",
      method: "post",
      data
    });
  },
  eventAttend(data) {
    return request({
      url: "/api/eventAttend",
      method: "post",
      data
    });
  },
  eventAddComment(data) {
    return request({
      url: "/api/eventAddComment",
      method: "post",
      data
    });
  },
  eventDeleteComment(data) {
    return request({
      url: "/api/eventDeleteComment",
      method: "post",
      data
    });
  },
  eventPackageList(data) {
    return request({
      url: "/api/eventPackageList",
      method: "post",
      data
    });
  },
  eventProgram(data) {
    return request({
      url: "/api/eventProgram",
      method: "post",
      data
    });
  }
};
