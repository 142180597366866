<template>
  <router-view v-if="isRouterAlive" />
</template>
<script>
import { socialvue } from "./config/pluginInit";

export default {
  name: "App",
  // 提供reload方法
  provide () {
    return {
      reload: this.reload,
    };
  },
  data () {
    return {
      isRouterAlive: true, // isRouterAlive控制显示
    };
  },

  watch:{
    '$route':{
      handler: async function(val, oldVal) {
        // console.log(val.path)
        // if(val.path == '/auth/sign-in1'){window.location.reload()}
        let token = localStorage.getItem('token');
        if(token){
          let res = await this.$http.verifyToken().then(res=>{
            if(res.status == 200){
              localStorage.setItem('token',res.data.token)
            }
          })
        }
      }

    },
  },

  methods: {
    reload () {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },

    async getLocalLang () {
      this.$i18n.locale = 'lang';
      let lang = localStorage.getItem('lang');
      if (lang != 'null') {
        // 如果本地有值-获取本地数据
        this.$i18n.setLocaleMessage("lang", (JSON.parse(lang)))
      } else {
        // 如果本地没值，拿本地文件的
        const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
        const messages = {}
        locales.keys().forEach(key => {
          const matched = key.match(/([A-Za-z0-9-_]+)\./i)
          if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
          }
        })
        this.$i18n.setLocaleMessage("lang", messages.en)
      }
    }
  },

  components: {},
  mounted () {
    // console.log('测试Nginx 配置后，让 index.html 不缓存。');
    socialvue.mainIndex();
    this.getLocalLang();

  },
};
</script>
<style lang="scss">
@import "assets/scss/style.scss";
@import "assets/fonts/font.css";
</style>