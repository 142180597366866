export default {

  // 头部+
  getAbout (state, data) {
    state.about = data;
  },


}

