import { Message } from 'element-ui'
import { getlocalStorage, setlocalStorage } from '@/Utils/localStorage'
import router from '@/router'
import md5 from '@/Utils/md5'
import { getRsaKeys, RSADecryptRSA, RSAEncrypt } from '@/Utils/RSA'
import { AESDecrypt, AESEncrypt } from '../Utils/Aes'
import request from '@/http/request'

// 获取服务端公匙
function getPublicKey (data) {
  return request({
    url: '/api/getPublicKey',
    method: 'post',
    data
  })
}
function getPubKey () {
  return request({
    baseURL: 'https://api-masterclass.jci.cc', //正式
    // baseURL: 'https://mc-api.jybtx.com', //测试
    url: '/api/getPubKey'
  })
}
const lang = getlocalStorage('lang') || 'cn'

const errnoWhiteCode = []
// 常规提示错误码
const errnoCode = [1000, 9999]
// 大弹框错误码
const errnoCodeBig = []
// 网络错误提示错误码
const errnoNetwork = [1000, 1099]
// 指定重新登录
const errnoLoginCode = [
  1006
  // 2016,//被顶下线
  // 2034,//权限更改
]
// 免token接口
const exemptTokenApi = [
  '/api/register', // 注册
  '/api/login', // 登录
  '/api/forgotPassword', // 忘记密码发送邮件
  '/api/getLoginProblem', // 获取登录问题
  '/api/problemLogin', // 三个问题登录
  '/api/getAddress', // 注册获取地址
  '/api/getOrganizationList', // 注册问题获取组织
  '/api/login2fa', // 2fa登录
  '/api/captcha', // 登录验证码
  '/api/getWebLanguageList',
  '/api/getWebLanguageData',
  '/api/reactivate',
  '/api/reset2fa',
  '/api/emailGet2faStatus',
  '/api/getUnfreezeProblem',
  '/api/applyUnfreeze',
  '/api/getPublicKey',
  '/api/termDetail',
  '/api/getPubKey', // 新项目公钥接口
  '/api/programAgentScoreList',
  '/api/getUserProgramQuestionnaireInfo',
  '/api/programJudgeScore',
  '/api/deleteAccount'
]
// 没有具体响应内容并且没有错误码
const noDateApi = ['/api/clearFrienSubscript', '/api/sendChat', '/api/clearGroupSubscript']

// 错误码弹出相对应的弹框
function funErrnoCode (data) {
  const code = data.status
  // console.log(data)
  // 提示错误码需要在页面中做出相应操作
  if (errnoWhiteCode.includes(code)) return
  // 重新登录
  if (errnoLoginCode.includes(code)) {
    Message.warning(`${data.message}`)
    localStorage.clear()
    router.push('/login')
  } else if (code >= errnoCode[0] && code <= errnoCode[1]) {
    Message.warning(`${data.message}[errno: ${data.status}]`)
    // 网络错误提示错误码
  } else if (code >= errnoNetwork[0] && code <= errnoNetwork[1]) {
    Message.warning({
      message: lang == 'en' ? `Network error, please try again later![errno: ${data.errno}]` : lang == 'cn' ? `$网络错误，请稍后再试[errno: ${data.errno}]  ` : `網絡錯誤請稍後重試！[errno: ${data.errno}]`
    })
  }
}

// 判断是否是免token接口
function funExemptTokenApi (url) {
  if (!exemptTokenApi.includes(url)) {
    // 重定向到登录页面
    localStorage.clear()
    router.push('/auth/sign-in1')
    // Message.warning({
    //   message: lang == 'en' ? 'Access failed, please login again' : lang == 'cn' ? '访问失败，请重新登陆' : '訪問失敗，請重新登陸'
    // })
  }
}

// 报红错误码相应操作
function funErrRequest (err) {
  if (String(err).indexOf('Network Error') > 0 || String(err).indexOf('timeout') > 0) {
    Message.warning({
      message: lang == 'en' ? 'Network error, please try again later!' : lang == 'cn' ? '网络错误，请稍后再试' : '網絡錯誤請稍後重試!'
    })
  }
  switch (err.response.status) {
    case 400: case 404: case 500:
      Message.warning({
        message: lang == 'en' ? 'Network error, please try again later!' : lang == 'cn' ? '网络错误，请稍后再试' : '網絡錯誤請稍後重試!'
      })
      break
    case 401:
      Message.warning({
        message: lang == 'en' ? 'Long time no operation, please login again!' : lang == 'cn' ? '长时间未操作，请重新登录！' : '長時間未操作，請重新登錄！'
      })
      localStorage.clear()
      // router.push('/login')
      break
    case 403:
      let newToken = err.response.headers.token
      setlocalStorage('token', newToken)
      err.response.config.headers.token = newToken
      return err.response.config
  }
}

// 判断是哪种加密传输
async function funEncryption (options) {
  // console.log(options)
  let server_public_key // 服务端公钥
  let server_public_key_md5// 服务端公钥md5
  let public_key_use // 客户端公钥
  let private_key // 客户端私钥
  let key // ASE密钥
  if (options.encryptionType) {
    let params
    if (options.encryptionType == 1) {
      // 请求获取公匙
      let res
      if (options.authorizeType == 1) {
        res = await getPubKey()
      } else {
        res = await getPublicKey()
      }
      server_public_key = res.data.public
      // console.log('服务端获取的公钥 = >', server_public_key);
      server_public_key_md5 = md5(server_public_key)
      // console.log('服务端公钥md5 = >', server_public_key);

      // 客户端公私钥
      const obj = await getRsaKeys()
      public_key_use = obj.publicKey
      // console.log('客户端公钥 = >', public_key_use);
      private_key = obj.privateKey
      // console.log('客户端私钥 = >', private_key);
      if (options.fileFormData) {
        if (options.params) {
          options.file = options.params.get('file')
          options.params = {
            paid: options.params.get('paid'),
            file_code: options.params.get('file_code')
          }
        } else if (options.data) {
          options.file = options.data.get('file')
          options.data = {
            paid: options.data.get('paid'),
            file_code: options.data.get('file_code')
          }
        }
        // ASE密钥
        let AESOBJ = AESEncrypt(JSON.stringify(options.params || options.data))
        // console.log('客户端随机AES密钥 = >', AESOBJ.key);
        key = RSADecryptRSA(server_public_key, AESOBJ.key)

        let data = AESOBJ.crypted
        let formData = new FormData()
        formData.append('public_key', public_key_use)
        formData.append('public_key_md5', server_public_key_md5)
        formData.append('key', key)
        formData.append('data', data)
        formData.append('file', options.file)
        params = formData
      } else {
        // ASE密钥
        let AESOBJ = AESEncrypt(JSON.stringify(options.params || options.data))
        // console.log('客户端AES密钥 = >', AESOBJ);
        key = RSADecryptRSA(server_public_key, AESOBJ.key)

        let data = AESOBJ.crypted
        params = {
          public_key: public_key_use,
          pubKeyMd5: server_public_key_md5,
          random: key,
          data
        }
      }
    } else if (options.encryptionType == 2) {
      let res
      if (options.authorizeType == 1) {
        res = await getPubKey()
      } else {
        res = await getPublicKey()
      }
      server_public_key = res.data.public
      // console.log('服务端公钥 = >', server_public_key);

      server_public_key_md5 = md5(server_public_key)
      // console.log('服务端公钥md5 = >', server_public_key);

      let AESOBJ = AESEncrypt(JSON.stringify(options.params || options.data))
      // console.log('客户端AES密钥 = >', AESOBJ.key);

      key = RSADecryptRSA(server_public_key, AESOBJ.key)

      let data = AESOBJ.crypted
      params = {
        pubKeyMd5: server_public_key_md5,
        random: key,
        data
      }
    } else if (options.encryptionType == 3) {
      // 获取客户端公私钥
      const obj = await getRsaKeys()
      public_key_use = obj.publicKey
      // console.log('客户端公钥 = >', public_key_use);
      private_key = obj.privateKey
      // console.log('客户端私钥 = >', private_key);
      let data = options.params || options.data
      params = {
        public_key: public_key_use,
        ...data
      }
    }
    if (options.hasOwnProperty('params')) {
      options.params = params
    } else {
      options.data = params
    }
    // console.log({
    //   server_public_key, // 服务端公钥
    //   server_public_key_md5, // 服务端公钥md5
    //   public_key_use, // 客户端公钥
    //   private_key, // 客户端私钥
    //   key // ASE密钥
    // })
    return {
      server_public_key, // 服务端公钥
      server_public_key_md5, // 服务端公钥md5
      public_key_use, // 客户端公钥
      private_key, // 客户端私钥
      key // ASE密钥
    }
  }
}
// 判断是哪种解密
function funDecryptEncryption (options, private_key, data) {
  // 返回参数需要解密
  if (options.encryptionType && options.encryptionType != 2 && data.data) {
    // console.log("data",data)
    // 使用客户端私钥解密后端返回的用客户端公钥加密的服务端AES_key
    const res_AES_key = RSAEncrypt(private_key, data.sign)
    // console.log(res_AES_key, data.data)
    // 使用AES_key解密data数据
    data.data = JSON.parse(AESDecrypt(res_AES_key, data.data))
    // console.log("解密",data.data)
  }
}
// 没有具体响应内容并且没有错误码一般为是操作，比如删除，编辑，提示 操作成功
function funNoDataApi (response) {
  // 一些没有响应内容的接口不需要提示操作成功
  if (!noDateApi.includes(response.config.url)) {
    Message.success({
      message: lang == 'en' ? 'Operation is successful！' : ''
    })
  }
}

// 排序
function sortNumber (a, b) {
  return a - b
}
export { funErrnoCode, funExemptTokenApi, funErrRequest, funEncryption, sortNumber, funDecryptEncryption, funNoDataApi }
