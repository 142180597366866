// 解密获取
function getlocalStorage (name) {
  return localStorage.getItem(name)
  // if (process.env.NODE_ENV === 'development') {
  //   return localStorage.getItem(name)
  // } else {
  //   const newName = window.btoa(encodeURIComponent(name))
  //   const data = localStorage.getItem(newName)
  //   if (data) {
  //     return decodeURIComponent(window.atob(data))
  //   } else {
  //     return data
  //   }
  // }
}
// 加密储存
function setlocalStorage (name, data) {
  localStorage.setItem(name, data)
  // if (process.env.NODE_ENV === 'development') {
  //   localStorage.setItem(name, data)
  // } else {
  //   if (data) {
  //     localStorage.setItem(window.btoa(encodeURIComponent(name)), window.btoa(encodeURIComponent(data)))
  //   } else {
  //     localStorage.setItem(window.btoa(encodeURIComponent(name)), data)
  //   }
  // }
}
// 删除 储存
function remlocalStorage (name) {
  localStorage.removeItem(name)
  // if (process.env.NODE_ENV === 'development') {
  //   localStorage.removeItem(name)
  // } else {
  //   const newName = window.btoa(encodeURIComponent(name))
  //   localStorage.removeItem(newName)
  // }
}

export { getlocalStorage, setlocalStorage, remlocalStorage }
