import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  is_register: false,

  // 头部
  headerData: {},

  // intro
  introData: {},
  sponsors: [],
  partners: [],
  project_single: {},


  postComment: 0,
  package_type: 0,

  allProjectDetail: {},


  certificate: [],
  questionnaire: [],
  result: {},

  // 评论总分
  commentLastScore: {},

  // 四个蓝方块的数据
  // all program
  all_program: {},
  index: 0,
  tabIndex: 0,

  // my_shedule/live_program
  single_schedule: [],


  // 支付弹框内容
  payModalContent: "",

  //plantobuy 
  single_program: {},
  full_package: {},
  online_ticket: {},
  package_program: {},

  // 勾选单一价格
  checkedList: [],

  // 勾选当天价格
  checkedPackageList: [],

  // online /all pass
  checkAllPassList: [],

  // package
  buyPackageList: [],

  // tab-attendees
  attendeesList: [],
  addressDis: false,

  // tab-coc
  cocList: [],
  cocAddressDis: false,

  // new-tab
  tabListData: [],

  // vuex的id和ajaxType
  typeId: {
    1: 'project_id',
    2: 'event_id',
    3: 'course_id',
    4: 'meeting_id',
  }

}

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations
}
