import request from "../request";
export default {
  //多语言
  getWebLanguageList(data) {
    return request({
      url: "/api/getWebLanguageList",
      method: "POST",
      data,
    });
  },
  getWebLanguageData(data) {
    return request({
      url: "/api/getWebLanguageData",
      method: "POST",
      data,
    });
  },
  // 支付接口
  payMent(data) {
    return request({
      url: "/api/payment",
      method: "POST",
      data,
    });
  },
  modelRecharge(data) {
    return request({
      url: "/api/modelRecharge",
      method: "POST",
      data,
    });
  },
  // 会员支付轮询
  getVipOrderStatus(data) {
    return request({
      url: "/api/getVipOrderStatus",
      method: "POST",
      data,
    });
  },
  publicPolling(data) {
    return request({
      url: "/api/modelPayment-polling",
      method: "POST",
      data,
    });
  },
  // 支付475轮询
  payPolling(data) {
    return request({
      url: "/api/payment-polling",
      method: "POST",
      data,
    });
  },
  uploadImages(data) {
    return request({
      url: "/api/uploadImage",
      method: "POST",
      data,
    });
  },
  getUserFriendList(data) {
    return request({
      url: "/api/userFriendList",
      method: "post",
      data,
    });
  },
  friendRequest(data) {
    return request({
      url: "/api/friendRequest",
      method: "post",
      data,
    });
  },
  // 文件上传
  uploadFile(data) {
    return request({
      url: "/api/uploadFile",
      method: "POST",
      data,
    });
  },
  // 打赏
  tipping(data) {
    return request({
      url: "/api/tipping",
      method: "POST",
      data,
    });
  },
  // 相册名称列表
  allAlbumList(data) {
    return request({
      url: "/api/albumList",
      method: "POST",
      data,
    });
  },
  // 相册
  getAllAlbums(data) {
    return request({
      url: "/api/allAlbums",
      method: "POST",
      data,
    });
  },
  addAlbum(data) {
    return request({
      url: "/api/addAlbum",
      method: "POST",
      data,
    });
  },

  albumPhotos(data) {
    return request({
      url: "/api/albumPhotos",
      method: "POST",
      data,
    });
  },
  editAlbum(data) {
    return request({
      url: "/api/editAlbum",
      method: "POST",
      data,
    });
  },
  delAlbum(data) {
    return request({
      url: "/api/delAlbum",
      method: "POST",
      data,
    });
  },

  // 获取媒体Photo
  getAllPhoto(data) {
    return request({
      url: "/api/allPhotos",
      method: "POST",
      data,
    });
  },

  editPhoto(data) {
    return request({
      url: "/api/editPhoto",
      method: "POST",
      data,
    });
  },
  getAllVideos(data) {
    return request({
      url: "/api/allVideos",
      method: "POST",
      data,
    });
  },
  // 获取洲、国家、地区
  getAddress(data) {
    return request({
      url: "/api/getAddress",
      method: "POST",
      data,
    });
  },
  // 获取组织列表
  getOrganizationList(data) {
    return request({
      url: "/api/getOrganizationList",
      method: "POST",
      data,
    });
  },
  // 获取时区
  getTimeArea(data) {
    return request({
      url: "/api/getTimeArea",
      method: "POST",
      data,
    });
  },
  // 获取当前与设置时区时间
  getMyTimeArea(data) {
    return request({
      url: "/api/getMyTimeArea",
      method: "POST",
      data,
    });
  },
  // 会费支付回调
  vipPayResponse(data) {
    return request({
      url: "/api/vipPayResponse",
      method: "POST",
      data,
    });
  },
  // 全局搜索
  getSearchResult(data) {
    return request({
      url: "/api/getSearchResult",
      method: "POST",
      data,
    });
  },
  // 获取用户余额与价格
  getPayConfig(data) {
    return request({
      url: "/api/getPayConfig",
      method: "POST",
      data,
    });
  },
  // 公共支付
  payMoney(data) {
    return request({
      url: "/api/payMoney",
      method: "POST",
      data,
    });
  },
  //
  packageList(data) {
    return request({
      url: "/api/packageList",
      method: "POST",
      data,
    });
  },
  invoice(data) {
    return request({
      url: "/api/invoice",
      method: "POST",
      data,
    });
  },
  // library
  getLibrary(data) {
    return request({
      url: "/api/getLibrary",
      method: "POST",
      data,
    });
  },
  getTipPayee(data){
    return request({
      url: "/api/getTipPayee",
      method: "POST",
      data,
    });
  },
  getUserTips(data){
    return request({
      url: "/api/getUserTips",
      method: "POST",
      data,
    });
  },

  // Stripe支付成功
  stripeSuccess(data){
    return request({
      url: "/api/stripeSuccess",
      method: "POST",
      data,
    });
  },

  // PayPal支付成功
  payPalSuccess(data){
    return request({
      url: "/api/payPalSuccess",
      method: "POST",
      data,
    });
  },

  // 验证token
  verifyToken(data) {
    return request({
      url: "/api/verifyToken",
      method: "get",
      data,
    });
  },


};
