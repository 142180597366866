var crypto = require('crypto')

// AES
// const key = '1F8044FF3EF1F016';  //十六位十六进制数作为密钥
const iv = '1234567887654321' // 十六位十六进制数作为密钥偏移量

// 解密方法
function AESDecrypt (key, crypted) {
  crypted = new Buffer(crypted, 'base64').toString('binary')
  var decipher = crypto.createDecipheriv('aes-256-cbc', key, iv)
  var decoded = decipher.update(crypted, 'binary', 'utf8')
  decoded += decipher.final('utf8')
  return decoded
}

// 加密方法
function AESEncrypt (data) {
  const key = randomGenerate(32)
  var cipher = crypto.createCipheriv('aes-256-cbc', key, iv)
  var crypted = cipher.update(data, 'utf8', 'binary')
  crypted += cipher.final('binary')
  crypted = new Buffer(crypted, 'binary').toString('base64')
  return { crypted, key }
}

// 随机生成16位
function randomGenerate (length) {
  const chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E',
    'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  if (!Number.isInteger(length) || length <= 0) { // 合法性校验
    console.error('请检查输入随机字符串长度是否为正整数！')
    return 'Error'
  }
  let randomString = ''
  for (let i = 0; i < length; i++) {
    randomString += chars[Math.floor(Math.random() * chars.length)]
  }
  return randomString
}
export {
  AESDecrypt,
  AESEncrypt
}
