import request from "../request";
export default {
  walletAllInfo(params) {
    return request({
      url: "/api/userWalletRecord",
      method: "get",
      params
    });
  },
  recharge(data) {
    return request({
      url: "/api/recharge",
      method: "post",
      data
    });
  },
  testRecharge(data) {
    return request({
      url: "/api/testRecharge",
      method: "post",
      data
    });
  },
  rechargeResponse(data) {
    return request({
      url: "/api/rechargeResponse",
      method: "post",
      data
    });
  },
  // 钻石比例
  diamondsRatio(params) {
    return request({
      url: "/api/diamonds-ratio",
      method: "get",
      params
    });
  },
  // 支付475轮询
  payPolling(data) {
    return request({
      url: "/api/payment-polling",
      method: "POST",
      data
    });
  },


  // payPal
  paypal(data) {
    return request({
      url: "/api/paypal",
      method: "POST",
      data
    });
  },
  paypalSuc(data) {
    return request({
      url: "/api/paypal/checkout-success",
      method: "POST",
      data
    });
  },

  // 积分列表
  pointsList(data) {
    return request({
      url: "/api/pointsList",
      method: "POST",
      data
    });
  },
  // 删除流水
  deleteRecharge(data){
    return request({
      url: "/api/delete-up",
      method: "POST",
      data
    });
  },
  // 充值订单
  rechargeList(data){
    return request({
      url: "/api/invoice-list-wallet",
      method: "POST",
      data
    });
  },
};
