// 聊天室为不同baseURL
const chatApiList = [
  '/api/bind',
  '/api/bindGroup',
  '/api/getUserFriend',
  '/api/editGroupIntro',
  '/api/getOfflineFriendsMessage',
  '/api/getOfflineGroupMessage',
  '/api/sendGroup',
  '/api/getFriendInformation',
  '/api/createGroup',
  '/api/updateGroupNotice',
  '/api/inviteFriendGroup',
  '/api/updateGroupAlias',
  '/api/getGroupUserList',
  '/api/removeGroupUser',
  '/api/sendChat',
  '/api/getUserGroup',
  '/api/updateGroupName',
  '/api/exitGroup',
  '/api/getFriendsSubscript',
  '/api/getGroupSubscript',
  '/api/clearGroupSubscript',
  '/api/clearFrienSubscript',
  '/api/getNewsSubscript',
  '/api/getChatRoomList',
  '/api/delChatMsg',
  '/api/getFriendInfo',

  // 会议聊天
  '/api/addModelChat',
  '/api/sendModelChat',
  '/api/getModelChat',

  // 组织成员加入群聊
  '/api/createOrganizationGroup'
]

// 不需要loading的请求
const removeLoadingList = [
  // '/api/bind', // 聊天室绑定
  '/api/sendChat',  // 聊天室发送消息
  '/api/sendGroup', // 聊天室发送群消息
  '/api/getAddress', // 获取国家地区
  '/api/getOrganizationList', // 获取国家地区
  '/api/getNewsSubscript', // 获取好友和群组最新消息
  '/api/getUserGroup',  // 侧边栏获取群组
  '/api/getUserFriend',  // 侧边栏获取好友
  '/api/getUserInfo',
  '/api/UnreadMessage',
  '/api/getGoogleSearchNearby', //地址列表
  '/api/uploadImage',//上传图片
  '/api/systemList', //系统消息
  '/api/userRequestList',//好友消息
  '/api/payment-polling', //充值支付轮询
  '/api/modelPayment-polling', //公共支付轮询
  '/api/eventPayment-polling',
  '/api/coursePayment-polling',
  '/api/meetingPolling',
  '/api/courseCertificatePayment-polling',
  '/api/getVipOrderStatus', //支付会费轮询

  '/api/getProductClassify',

  "/api/createPost",//发帖
  // "/api/userPostList",//获取帖子
  "/api/likePost",//点赞
  "/api/likePostComment",//点赞帖子评论
  "/api/postComment",//評論帖子
  "/api/getUserPostList",//帖子列表

  "/api/delUserPostCommentLord",//删除帖子评论
  "/api/getPostComment",//获取帖子评论
  "/api/getPostInfo",//帖子詳情
  "/api/getPostCommentInfo",//頂級評論

  // 会议聊天
  '/api/addModelChat',
  '/api/sendModelChat',
  '/api/getModelChat',


  // 首页
  '/api/userPostList',
  '/api/getNewsSubscript'



  // '/api/getOrganizationList', // 注册获取分会
  // '/api/uploadImage',
  // '/api/getGoogleSearchNearby',
  // '/api/salonDeleteLike',   // 删除评论
  // '/api/salonLikeComment',   // 删除评论
  // // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓  =>  loading 出现两次暂未排查
  // '/api/getUserFriend',
  // '/api/getUserInfo',
  // '/api/getNewsSubscript',
  // '/api/getUserGroup',
  // '/api/getChatRoomList',
  // '/api/getGroupUserList',
  // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

]


export {
  chatApiList,
  removeLoadingList
}