import getters from './getters'
// import actions from './actions'
import mutations from './mutations'


const state = {
  userType: 0,

}


export default {
  state,
  getters,
  mutations
}