export default {
  CHANGE_DATA_CHAT(state, data) {
    if (data.type == 'userInfo') {
      // 用户信息
      data.data.forEach((v, i) => {
        if (v.type == 'nick_name') {
          state.dataChat.nick_name = v.nick_name
        } else if (v.type == 'avatar') {
          state.dataChat.avatar = v.avatar
        } else if (v.type == 'client_id') {
          state.dataChat.client_id = v.client_id
        } else if (v.type == 'user_id') {
          state.dataChat.user_id = v.user_id
        }
      })
    } else if (data.type == 'friendsList') {
      // 好友列表
      state.dataChat.friendsList = data.friendsList
    } else if (data.type == 'groupList') {
      // 群聊分组列表
      state.dataChat.groupList = data.groupList
    } else if (data.type == 'chatMessage') {
      // 接收的聊天消息
      state.dataChat.chatMessage = data.chatMessage
    } else if (data.type == 'firendsMessage') {
      // 接收的离线消息
      state.dataChat.firendsMessage = data.firendsMessage
    } else if (data.type == 'friendsInfoNum') {
      // 好友消息数量
      state.dataChat.friendsInfoNum = data.friendsInfoNum
    } else if (data.type == 'groupInfoNum') {
      // 群组消息数量
      state.dataChat.groupInfoNum = data.groupInfoNum
    } else if (data.type == 'groupInfoMessage') {
      // 群组消息数量
      state.dataChat.groupInfoMessage = data.groupInfoMessage
    }
  },
  CHANGE_CURRENT_CHAT(state, data) {
    state.showCurrentChat = data;
  }

}

