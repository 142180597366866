import request from '../request'
export default {

  // 首页广告列表
  getHomeAd(params) {
    return request({
      url: '/api/getHomeAd',
      method: 'get',
      params
    })
  },

  // 首页广告列表(左侧)
  getHomeAdLift(params) {
    return request({
      url: '/api/getHomeAdLift',
      method: 'get',
      params
    })
  },

  // 点击广告
  adHitsIncr(data) {
    return request({
      url: '/api/adHitsIncr',
      method: 'post',
      data
    })
  },


  // 获取首页随机帖子
  getHomePostList(params) {
    return request({
      url: '/api/userPostList',
      method: 'get',
      params
    })
  },


  getUserHomePostList(data) {
    return request({
      url: '/api/getUserPostList',
      method: 'post',
      data
    })
  },


  /**
   * ----------发帖相关--------------------
   * */
  createPost(data) {
    return request({
      url: '/api/createPost',
      method: 'post',
      data
    })
  },

  // 删除帖子
  delUserPost(data) {
    return request({
      url: '/api/delUserPost',
      method: 'post',
      data
    })
  },
  // 点赞帖子
  likePost(data) {
    return request({
      url: '/api/likePost',
      method: 'post',
      data
    })
  },


  // 评论帖子
  commentPost(data) {
    return request({
      url: '/api/postComment',
      method: 'post',
      data
    })
  },

  // 点赞帖子评论
  likePostComment(data) {
    return request({
      url: '/api/likePostComment',
      method: 'post',
      data
    })
  },
  // 删除评论
  delPostComment(data) {
    return request({
      url: '/api/delUserPostCommentLord',
      method: 'post',
      data
    })
  },


  // 获取顶级评论
  getPostCommentInfo(data) {
    return request({
      url: '/api/getPostCommentInfo',
      method: 'post',
      data
    })
  },
  // 获取二级评论
  getChildComment(data) {
    return request({
      url: '/api/getPostComment',
      method: 'post',
      data
    })
  },
  postDetail(data) {
    return request({
      url: '/api/getPostInfo',
      method: 'post',
      data
    })
  },

  delSelfPostCommentVice(data) {
    return request({
      url: '/api/delUserPostCommentVice',
      method: 'post',
      data
    })
  },

  googleNearby(data) {
    return request({
      url: '/api/getGoogleSearchNearby',
      method: 'post',
      data
    })
  },

  // 获取帖子详情
  getPostInfo(data) {
    return request({
      url: '/api/getPostInfo',
      method: 'post',
      data
    })
  },

  // left
  getEvent(data) {
    return request({
      url: '/api/homePage',
      method: 'post',
      data
    })
  },

  // 通知
  userRequestList(params) {
    return request({
      url: '/api/userRequestList',
      method: 'get',
      params
    })
  },

  userRequestConfirm(data) {
    return request({
      url: '/api/userRequestConfirm',
      method: 'post',
      data
    })
  },
  userRequestDel(data) {
    return request({
      url: '/api/userRequestDel',
      method: 'post',
      data
    })
  },

  // 举报
  complain(data) {
    return request({
      url: '/api/complain',
      method: 'post',
      data
    })
  },

  termDetail(data) {
    return request({
      url: '/api/termDetail',
      method: 'post',
      data
    })
  },

  updatePostInfo(data) {
    return request({
      url: '/api/updatePostInfo',
      method: 'post',
      data
    })
  },

  getPostLike(params) {
    return request({
      url: '/api/getPostLike',
      method: 'get',
      params
    })
  },
  getPostShare(params) {
    return request({
      url: '/api/getPostShare',
      method: 'get',
      params
    })
  },

  // 隐藏帖子
  hidePosts(data) {
    return request({
      url: '/api/hidePosts',
      method: 'post',
      data
    })
  },

  // 拉黑用户
  pullBlack(data) {
    return request({
      url: '/api/pullBlack',
      method: 'post',
      data
    })
  },

  // 删除已拉黑用户
  closePullBlack(data) {
    return request({
      url: '/api/closePullBlack',
      method: 'post',
      data
    })
  },

  // 获取拉黑用户列表
  getPullBlackList(params) {
    return request({
      url: '/api/getPullBlackList',
      method: 'get',
      params
    })
  },

}