
// 加解密
const encryption = true
// 路由权限
const routerFlag = false
const version = 'v1'

let BASE_URL
let baseUrl
let chatWsUrl
let chatRequestUrl
// let stripeUrl
// console.log('process.env.VUE_APP_MODE', process.env)

if (process.env.VUE_APP_MODE === 'development') {
  // 开发环境下的执行操作
  // BASE_URL = 'https://api.jybtx.com'
  // baseUrl = 'https://api.jybtx.com'// 测试
  BASE_URL = 'https://jci-api.qlclient.com'
  baseUrl = 'https://jci-api.qlclient.com'// 测试
  // chatWsUrl = 'wss://jci-chat.jybtx.com/ws'// 测试聊天
  // chatRequestUrl = 'https://jci-chat.jybtx.com'// 测试聊天
  chatWsUrl = 'wss://jci-chat-api.qlclient.com/ws'// 测试聊天
  chatRequestUrl = 'https://jci-chat-api.qlclient.com'// 测试聊天
  // BASE_URL = 'https://api.jciuatsys.com'
  // stripeUrl = 'https://jci-web.jybtx.com'
} else if (process.env.VUE_APP_MODE === 'test') {
  // 测试环境下的执行操作
  BASE_URL = 'https://api.jciuatsys.com' // 测试服务器上的
  baseUrl = 'https://api.jciuatsys.com'// 测试服务器上的
  // chatWsUrl = 'wss://jci-chat.jybtx.com/ws'// 公司服务器上聊天的
  // chatRequestUrl = 'https://jci-chat.jybtx.com'// 公司服务器上聊天的
  chatWsUrl = 'wss://mapi.jciuatsys.com/ws'// 公司服务器上聊天的
  chatRequestUrl = 'https://mapi.jciuatsys.com/'// 公司服务器上聊天的
  // stripeUrl = 'https://jciuatsys.com'
} else {
  // 生产环境下的执行操作
  BASE_URL = 'https://api.jci.cc'// 正式服务器的
  baseUrl = 'https://api.jci.cc'// 正式服务器的
  chatWsUrl = 'wss://mapi.jci.cc/ws'// 正式服务器的
  chatRequestUrl = 'https://mapi.jci.cc'// 正式服务器的
  // stripeUrl = 'https://jvc.jci.cc/web'
}

// const baseUrl = 'http://jci-web.summer.kim';
export {
  encryption,
  routerFlag,
  BASE_URL,
  version,
  baseUrl,
  chatWsUrl,
  chatRequestUrl,
  // stripeUrl
}
