import Vue from 'vue'
import Vuex from 'vuex'
import Setting from './Setting/index'
import ChatRoom from './ChatRoom/index'
import Group from './Group/index'
import Public from './Public/index'
import Process from './NewProcess/index'
import Channel from './Channel/index'
import meetingChat from './MeetingChat/index'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Setting,
    ChatRoom,
    Group,
    Public,
    Process,
    Channel,
    meetingChat
  },

  strict: debug
})
