export default {
  USEREINFO(state, data) {
    // console.log("data",data);
    state.userInfo = data;
  },
  COUNTRY(state, data) {
    state.country = data;
  },
  REGION(state, data) {
    state.region = data;
  },
  GET_COUNTRY_LIST(state, data) {
    state.countryList = data;
  },
  GET_REGION_LIST(state, data) {
    state.regionList = data;
  },
  CHANGE_IS_DISABLED(state, data) {
    state.isDisable = data;
  },
  CHANGE_IS_SHOW(state, data) {
    state.isShow = data;
  },
  DESTROYED_CLEAR(state, data) {
    state.regionList = [];
  },

  CHANGE_USER_INFO(state, data) {
    state.userInfo = data;
  },

  CHANGE_PAY_STATUS(state, data) {
    state.payStatus = data;
  },
  CHANGE_HPP_RESPONSE(state, data) {
    state.hppResponse = data;
  },

  GET_LANG_DATA(state, data) {
    // console.log(data);
    state.lang = data;
  },

  CHANGE_LIST(state, data) {
    state.is_list = data
  },

  CHANGE_DRAFT(state, data) {
    state.is_draft = data
  },
  CHANGE_PAGE(state, data){
    state.pageState = data
  }
}
