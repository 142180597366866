import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  dataChat: {
    nick_name: '', // 用户名字
    avatar: '', // 用户头像
    user_id: '', // 用户id
    client_id: '', // 服务器返回
    friendsList: [], // 好友列表
    groupList: [], // 群列表
    chatMessage: [], // 接收的聊天消息
    offlineMessage: [], // 接收的离线消息
    friendsInfoNum:[], // 好友列表消息数量
    groupInfoNum:[],  // 群组列表消息数量
    groupInfoMessage:[], // 群组消息
  },
  showCurrentChat:{}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
