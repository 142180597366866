import request from '../request'
export default {
  // group
  getGroupList (params) {
    return request({
      url: '/api/getGroupList',
      method: 'get',
      params
    })
  },

  addGroup (data) {
    return request({
      url: '/api/addGroup',
      method: 'post',
      data
    })
  },

  // 获取group信息

  updateGroupInfo (data) {
    return request({
      url: '/api/updateGroupInfo',
      method: 'post',
      data
    })
  },
  getBgImg (data) {
    return request({
      url: '/api/updateGroupBack',
      method: 'post',
      data
    })
  },

  // MyGroup
  getIAddGroupList (params) {
    return request({
      url: '/api/getIAddGroupList',
      method: 'get',
      params
    })
  },
  getIJoinGroupList (params) {
    return request({
      url: '/api/getIJoinGroupList',
      method: 'get',
      params
    })
  },

  dissolveGroup (data) {
    return request({
      url: '/api/delGroup',
      method: 'post',
      data
    })
  },
  exitSelfGroup (data) {
    return request({
      url: '/api/userExitGroup',
      method: 'post',
      data
    })
  },

  // GroupDetail-timline
  addGroupPost (data) {
    return request({
      url: '/api/addGroupPost',
      method: 'post',
      data
    })

  },

  // 获取帖子统计信息
  getGroupTotal (data) {
    return request({
      url: '/api/getGroupTotal',
      method: 'post',
      data
    })

  },


  joinGroup (data) {
    return request({
      url: '/api/joinGroup',
      method: 'post',
      data
    })
  },
  getGroupInfo (data) {
    return request({
      url: '/api/getGroupInfo',
      method: 'post',
      data
    })
  },

  getGroupPost (params) {
    return request({
      url: '/api/getGroupPostList',
      method: 'get',
      params
    })
  },

  groupPostComment (data) {
    return request({
      url: '/api/groupPostComment',
      method: 'post',
      data
    })
  },



  getGroupMember (data) {
    return request({
      url: '/api/getGroupMember',
      method: 'post',
      data
    })
  },


  getGroupPhoto (data) {
    return request({
      url: '/api/getGroupPhoto',
      method: 'post',
      data
    })
  },
  getGroupVideo (data) {
    return request({
      url: '/api/getGroupVideo',
      method: 'post',
      data
    })
  },

  // 晋升管理员
  updateGroupAdmin (data) {
    return request({
      url: '/api/updateGroupAdmin',
      method: 'post',
      data
    })
  },

  // 设为拥有者
  transferLeader (data) {
    return request({
      url: '/api/transferLeader',
      method: 'post',
      data
    })
  },

  // 剔除俱乐部
  kickOutGroup (data) {
    return request({
      url: '/api/kickOutGroup',
      method: 'post',
      data
    })
  },

  // 邀请介入俱乐部
  invateGroup (data) {
    return request({
      url: '/api/applyJoinGroup',
      method: 'post',
      data
    })
  },

  // 申请成为管理员
  applyBecomeAdmin (data) {
    return request({
      url: '/api/applyGroupAdmin',
      method: 'post',
      data
    })
  },

  // 获取俱乐部帖子详情
  getGroupPostDetail (data) {
    return request({
      url: '/api/getGroupPostInfo',
      method: 'post',
      data
    })
  },

  // 上传图片
  addClubPhoto (data) {
    return request({
      url: '/api/addClubPhoto',
      method: 'post',
      data
    })
  },

  // 上传视频
  addClubVideo (data) {
    return request({
      url: '/api/addClubVideo',
      method: 'post',
      data
    })
  },











}