import request from "../request";
export default {
  // 课程支付成功改变状态
  coursePayPalCheckoutSuccess(data){
    return request({
      url: "/api/coursePayPalCheckoutSuccess",
      method: "POST",
      data
    });
  },
  // 证书支付成功改变状态
  courseCertificatePayPalCheckoutSuccess(data){
    return request({
      url: "/api/courseCertificatePayPalCheckoutSuccess",
      method: "POST",
      data
    });
  },
  // 培训课程列表
  courseList(data) {
    return request({
      url: "/api/courseList",
      method: "POST",
      data
    });
  },
  // 课程详情
  courseDetail(data) {
    return request({
      url: "/api/courseDetail",
      method: "POST",
      data
    });
  },
  // 选项卡切换
  courseTab(data) {
    return request({
      url: "/api/courseTab",
      method: "POST",
      data
    });
  },
  // 点击参加(注册)
  courseAttend(data) {
    return request({
      url: "/api/courseAttend",
      method: "POST",
      data
    });
  },
  // 判断用户是否有权力点击链接
  courseUserHasPowerClickLink(data){
    return request({
      url: "/api/courseUserHasPowerClickLink",
      method: "POST",
      data
    });
  },
  // 添加评论
  courseAddComment(data) {
    return request({
      url: "/api/courseAddComment",
      method: "POST",
      data
    });
  },
  // 删除评论
  courseDeleteComment(data) {
    return request({
      url: "/api/courseDeleteComment",
      method: "POST",
      data
    });
  },
  // 套餐列表
  coursePackageList(data) {
    return request({
      url: "/api/coursePackageList",
      method: "POST",
      data
    });
  },
  // 获取课程各类行程
  courseProgram(data) {
    return request({
      url: "/api/courseProgram",
      method: "POST",
      data
    });
  },
  // 获取证书题目
  courseCertificateTopic(data) {
    return request({
      url: "/api/courseCertificateTopic",
      method: "POST",
      data
    });
  },
  // 提交试卷
  courseCertificateTopicSubmit(data) {
    return request({
      url: "/api/courseCertificateTopicSubmit",
      method: "POST",
      data
    });
  },
  // 获取证书详情
  courseCertificateInfo(data) {
    return request({
      url: "/api/courseCertificateInfo",
      method: "POST",
      data
    });
  },
  // 支付单品或套餐
  coursePayPackage(data) {
    return request({
      url: "/api/coursePayPackage",
      method: "POST",
      data
    });
  },
  // 添加行程到日程表
  courseAddProgram(data) {
    return request({
      url: "/api/courseAddProgram",
      method: "POST",
      data
    });
  },
  // 删除日程表的行程
  courseDeleteProgram(data) {
    return request({
      url: "/api/courseDeleteProgram",
      method: "POST",
      data
    });
  },
  // 支付考试
  coursePaperPayPackage(data) {
    return request({
      url: "/api/coursePaperPayPackage",
      method: "POST",
      data
    });
  },
  // 支付区块链证书
  courseCertificatePayPackage(data) {
    return request({
      url: "/api/courseCertificatePayPackage",
      method: "POST",
      data
    });
  },
  // 够买证书或试卷的第二步
  courseCertificateRecharge(data) {
    return request({
      url: "/api/courseCertificateRecharge",
      method: "POST",
      data
    });
  },
  // 课程章节获取链接或者二维码
  courseModuleInlet(data) {
    return request({
      url: "/api/courseModuleInlet",
      method: "POST",
      data
    });
  },
  // 点击链接签到
  courseModuleSignIn(data) {
    return request({
      url: "/api/courseModuleSignIn",
      method: "POST",
      data
    });
  },
  // 我的证书列表
  courseMyCertificate(data) {
    return request({
      url: "/api/courseMyCertificate",
      method: "POST",
      data
    });
  },
  // 课程支付
  courseRecharge(data) {
    return request({
      url: "/api/courseRecharge",
      method: "POST",
      data
    });
  },
  // 课程前端轮询查询
  coursePolling(data) {
    return request({
      url: "/api/coursePayment-polling",
      method: "POST",
      data
    });
  },
  // 购买证书轮询
  certificatePolling(data) {
    return request({
      url: "/api/courseCertificatePayment-polling",
      method: "POST",
      data
    });
  },
  // 问卷详情
  courseQuestionnaireContent(data) {
    return request({
      url: "/api/courseQuestionnaireContent",
      method: "POST",
      data
    });
  },
  // 问卷提交
  courseQuestionnaireSubmit(data) {
    return request({
      url: "/api/courseQuestionnaireSubmit",
      method: "POST",
      data
    });
  },
  // 获取课程订单列表
  courseOrderList(data) {
    return request({
      url: "/api/courseOrderList",
      method: "POST",
      data
    });
  },
  // 获取课程订单详情
  courseOrderDetail(data) {
    return request({
      url: "/api/courseOrderDetail",
      method: "POST",
      data
    });
  },
  // 删除课程订单
  delCourseOrder(data) {
    return request({
      url: "/api/delCourseOrder",
      method: "POST",
      data
    });
  },
  // 取消课程订单
  cancelCourseOrder(data) {
    return request({
      url: "/api/cancelCourseOrder",
      method: "POST",
      data
    });
  },
  // 课程订单申请退款
  applyCourseRefund(data) {
    return request({
      url: "/api/applyCourseRefund",
      method: "POST",
      data
    });
  },
  // 获取课程证书订单
  courseCertificateOrderList(data) {
    return request({
      url: "/api/courseCertificateOrderList",
      method: "POST",
      data
    });
  },
  // 课程考试订单列表
  coursePaperOrderList(data) {
    return request({
      url: "/api/coursePaperOrderList",
      method: "POST",
      data
    });
  },
  // 试卷/证书订单详情
  courseCertificateOrderDetail(data) {
    return request({
      url: "/api/courseCertificateOrderDetail",
      method: "POST",
      data
    });
  },
  // 删除试卷/证书订单
  delCourseCertificateOrder(data) {
    return request({
      url: "/api/delCourseCertificateOrder",
      method: "POST",
      data
    });
  },
  // 取消试卷/证书订单
  cancelCourseCertificateOrder(data) {
    return request({
      url: "/api/cancelCourseCertificateOrder",
      method: "POST",
      data
    });
  },
  // 试卷/证书订单申请退款
  applyCourseCertificateRefund(data) {
    return request({
      url: "/api/applyCourseCertificateRefund",
      method: "POST",
      data
    });
  }
};
