import request from '../request'
export default {
  getChannelList (data) {
    return request({
      url: '/api/channelList',
      method: 'POST',
      data
    })
  },
  channelDetail (data) {
    return request({
      url: '/api/channelDetail',
      method: 'POST',
      data
    })
  },
  channelTwinning (data) {
    return request({
      url: '/api/channelTwinning',
      method: 'POST',
      data
    })
  },

  getChannelPost (data) {
    return request({
      url: '/api/getChannelPost',
      method: 'POST',
      data
    })
  },

  channelFollow (data) {
    return request({
      url: '/api/channelFollow',
      method: 'POST',
      data
    })
  },
  channelAbout (data) {
    return request({
      url: '/api/channelAbout',
      method: 'POST',
      data
    })
  },
  channelFile (data) {
    return request({
      url: '/api/channelFile',
      method: 'POST',
      data
    })
  },
  operationChannelPost (data) {
    return request({
      url: '/api/operationChannelPost',
      method: 'POST',
      data
    })
  },
  channelPostLikePage(params){
    return request({
      url: '/api/channelPostLikePage',
      method: 'GET',
      params
    })
  },
  // 获取组织所有职称会员列表
  getOrganizationCareerList(params){
    return request({
      url: '/api/getOrganizationCareerList',
      method: 'GET',
      params
    })
  },
  // 获取所有会员
  getOrganizationMembersList(params){
    return request({
      url: '/api/getOrganizationMembersList',
      method: 'GET',
      params
    })
  },
  // 获取所有Senate
  getOrganizationSenateList(params){
    return request({
      url: '/api/getOrganizationSenateList',
      method: 'GET',
      params
    })
  },
  operationChannelPost(data){
    return request({
      url: '/api/operationChannelPost',
      method: 'post',
      data
    })
  },
  userAuth(data) {
    return request({
      url: '/api/userAuth',
      method: 'post',
      data
    })
  },
  createOrganizationGroup(data) {
    return request({
      url: '/api/createOrganizationGroup',
      method: 'post',
      data
    })
  },
}