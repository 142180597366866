import request from '../request'
export default {
  // 获取订单列表
  getOrderList (data) {
    return request({
      url: '/api/getOrderList',
      method: 'POST',
      data
    })
  },
  // 获取订单详情
  getOrderDetail (data) {
    return request({
      url: '/api/getOrderDetail',
      method: 'POST',
      data
    })
  },
  // 删除订单
  delOrder (data) {
    return request({
      url: '/api/delOrder',
      method: 'POST',
      data
    })
  },
  // 取消
  cancelOrder (data) {
    return request({
      url: '/api/cancelOrder',
      method: 'POST',
      data
    })
  },
  // 退款详情
  orderRefund (data) {
    return request({
      url: '/api/orderRefund',
      method: 'POST',
      data
    })
  },
  // 退款申请
  getApplyRefund (data) {
    return request({
      url: '/api/getApplyRefund',
      method: 'POST',
      data
    })
  }
}
