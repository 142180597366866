import request from "../request";
export default {
  getProgramList(params){
    return request({
      url:"/api/getProgramList",
      method:"get",
      params
    })
  },
  getProgramCategoryList(params){
    return request({
      url:"/api/getProgramCategoryList",
      method:"get",
      params
    })
  },
  getProgramDetail(params){
    return request({
      url:"/api/getProgramDetail",
      method:"get",
      params
    })
  },
  getProgramCertificate(params){
    return request({
      url:"/api/getProgramCertificate",
      method:"get",
      params
    })
  },
  setProgramQuestion(data){
    return request({
      url:"/api/setProgramQuestion",
      method:"post",
      data
    })
  },
  programPayPackage(data){
    return request({
      url:"/api/programPayPackage",
      method:"post",
      data
    })
  },
  programPayPalCheckoutSuccess(data){
    return request({
      url:"/api/programPayPalCheckoutSuccess",
      method:"post",
      data
    })
  },
  programOrderList(params){
    return request({
      url:"/api/programOrderList",
      method:"get",
      params
    })
  },
  programOrderDetail(params){
    return request({
      url:"/api/programOrderDetail",
      method:"get",
      params
    })
  },
  delProgramOrder(data){
    return request({
      url:"/api/delProgramOrder",
      method:"post",
      data
    })
  },
  cancelProgramOrder(data){
    return request({
      url:"/api/cancelProgramOrder",
      method:"post",
      data
    })
  },
  applyProgramRefund(data){
    return request({
      url:"/api/applyProgramRefund",
      method:"post",
      data
    })
  },
  applyProgramJudge(data){
    return request({
      url:"/api/applyProgramJudge",
      method:"post",
      data
    })
  },
  searchUserList(params){
    return request({
      url:"/api/searchUserList",
      method:"get",
      params
    })
  },
  relationEventOptions(params){
    return request({
      url:"/api/relationEventOptions",
      method:"get",
      params
    })
  },
  applyHistoryPage(params){
    return request({
      url:"/api/applyHistoryPage",
      method:"get",
      params
    })
  },

  // 20221208优化program
  // 获取Program问卷
  getProgramQuestionnaire(params) {
    return request({
      url: "/api/getProgramQuestionnaire",
      method: "get",
      params
    })
  },

  // 获取Program问卷信息
  getProgramQuestionnaireInfo(params) {
    return request({
      url: "/api/getProgramQuestionnaireInfo",
      method: "get",
      params
    })
  },

  // 提交Program问卷
  submitProgramQuestionnaire(data) {
    return request({
      url: "/api/submitProgramQuestionnaire",
      method: "post",
      data
    })
  },

  // 获取用户申请Program记录
  getUserProgramApply(params) {
    return request({
      url: "/api/getUserProgramApply",
      method: "get",
      params
    })
  },

  // 获取申请program记录信息
  getProgramApplyInfo(params) {
    return request({
      url: "/api/getProgramApplyInfo",
      method: "get",
      params
    })
  },

  // Program代理打分列表
  programAgentScoreList(params) {
    return request({
      url: "api/programAgentScoreList",
      method: "get",
      params
    })
  },

  // 获取用户Program问卷信息
  getUserProgramQuestionnaireInfo(params) {
    return request({
      url: "api/getUserProgramQuestionnaireInfo",
      method: "get",
      params
    })
  },

  // 评委打分
  programJudgeScore(data) {
    return request({
      url: "api/programJudgeScore",
      method: "post",
      data
    })
  },

  // 获取内容总数
  getContentNumber(data) {
    return request({
      url: '/api/getContentNumber',
      method: 'POST',
      data
    })
  },


}