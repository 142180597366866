import request from '../request'
export default {
  // 绑定客户端 上线client_id绑定user_id
  bind (data) {
    return request({
      url: '/api/bind',
      method: 'POST',
      data
    })
  },
  // 获取用户好友列表并判断是否在线
  getUserFriend (data) {
    return request({
      url: '/api/getUserFriend',
      method: 'POST',
      data
    })
  },
  // 获取好友信息
  getFriendInformation (data) {
    return request({
      url: '/api/getFriendInformation',
      method: 'POST',
      data
    })
  },
  // 上线获取好友离线消息
  getOfflineFriendsMessage (data) {
    return request({
      url: '/api/getOfflineFriendsMessage',
      method: 'POST',
      data
    })
  },
  // 一对一发送消息（好友聊天）
  sendChat (data) {
    return request({
      url: '/api/sendChat',
      method: 'POST',
      data
    })
  },
  // 群聊绑定客户端 上线client_id绑定group_id(加入群聊)
  bindGroup (data) {
    return request({
      url: '/api/bindGroup',
      method: 'POST',
      data
    })
  },
  // 上线获取群离线消息
  getOfflineGroupMessage (data) {
    return request({
      url: '/api/getOfflineGroupMessage',
      method: 'POST',
      data
    })
  },
  // 修改群聊得基础信息（接口）所有人可以修改
  editGroupIntro (data) {
    return request({
      url: '/api/editGroupIntro',
      method: 'POST',
      data
    })
  },
  // 群消息推送
  sendGroup (data) {
    return request({
      url: '/api/sendGroup',
      method: 'POST',
      data
    })
  },
  // 创建群聊
  createGroup (data) {
    return request({
      url: '/api/createGroup',
      method: 'POST',
      data
    })
  },
  // 修改群公告
  updateGroupNotice (data) {
    return request({
      url: '/api/updateGroupNotice',
      method: 'POST',
      data
    })
  },
  // 邀请好友进群
  inviteFriendGroup (data) {
    return request({
      url: '/api/inviteFriendGroup',
      method: 'POST',
      data
    })
  },
  // 修改群备注 (用户在群里的昵称)
  updateGroupAlias (data) {
    return request({
      url: '/api/updateGroupAlias',
      method: 'POST',
      data
    })
  },
  // 获取群成员列表
  getGroupUserList (data) {
    return request({
      url: '/api/getGroupUserList',
      method: 'POST',
      data
    })
  },
  // 踢人
  removeGroupUser (data) {
    return request({
      url: '/api/removeGroupUser',
      method: 'POST',
      data
    })
  },
  // 获取群列表
  getUserGroup (data) {
    return request({
      url: '/api/getUserGroup',
      method: 'POST',
      data
    })
  },
  // 修改群名称
  updateGroupName (data) {
    return request({
      url: '/api/updateGroupName',
      method: 'POST',
      data
    })
  },
  // 修改群名称
  exitGroup (data) {
    return request({
      url: '/api/exitGroup',
      method: 'POST',
      data
    })
  },
  // 获取好友聊天列表角标
  getFriendsSubscript (data) {
    return request({
      url: '/api/getFriendsSubscript',
      method: 'POST',
      data
    })
  },
  // 获取用户群聊天列表角标
  getGroupSubscript (data) {
    return request({
      url: '/api/getGroupSubscript',
      method: 'POST',
      data
    })
  },
  // 清除群聊天角标
  clearGroupSubscript (data) {
    return request({
      url: '/api/clearGroupSubscript',
      method: 'POST',
      data
    })
  },
  // 清除好友聊天角标
  clearFrienSubscript (data) {
    return request({
      url: '/api/clearFrienSubscript',
      method: 'POST',
      data
    })
  },
  // 清除好友聊天角标
  getNewsSubscript (data) {
    return request({
      url: '/api/getNewsSubscript',
      method: 'POST',
      data
    })
  },
  // 获取分享的内容是否存在
  sharedContentExist (data) {
    return request({
      url: '/api/sharedContentExist',
      method: 'POST',
      data
    })
  },
  // 获取聊天室列表
  getChatRoomList (data) {
    return request({
      url: '/api/getChatRoomList',
      method: 'POST',
      data
    })
  },
  // 删除聊天消息
  delChatMsg (data) {
    return request({
      url: '/api/delChatMsg',
      method: 'POST',
      data
    })
  },
  // 获取好友信息
  getFriendInfo (data) {
    return request({
      url: '/api/getFriendInfo',
      method: 'POST',
      data
    })
  },
  // 会议聊天
  addModelChat (data) {
    return request({
      url: '/api/addModelChat',
      method: 'POST',
      data
    })
  },
  // 获取好友信息
  sendModelChat (data) {
    return request({
      url: '/api/sendModelChat',
      method: 'POST',
      data
    })
  },

  // 获取会议聊天记录
  getModelChat (data) {
    return request({
      url: '/api/getModelChat',
      method: 'POST',
      data
    })
  },
  // 获取聊天表情
  getChatEmoji (data) {
    return request({
      url: '/api/getChatEmoji',
      method: 'POST',
      data
    })
  },
}
