import request from "../request";
export default {
  getClub100List(params) {
    return request({
      url: "/api/getClub100List",
      method: "get",
      params
    });
  },
  getClub100Detail(params) {
    return request({
      url: "/api/getClub100Detail",
      method: "get",
      params
    });
  },
  getClub100Contact(params) {
    return request({
      url: "/api/getClub100Contact",
      method: "get",
      params
    });
  },
  getClub100Config(params) {
    return request({
      url: "/api/getClub100Config",
      method: "get",
      params
    });
  },
  getClub100History(params) {
    return request({
      url: "/api/getClub100History",
      method: "get",
      params
    });
  },
  submitClubApplication(data) {
    return request({
      url: "/api/submitClubApplication",
      method: "POST",
      data
    });
  },

};
