import request from '../request'
export default {
  // 参议院详情
  getSenateDetail (data) {
    return request({
      url: '/api/getSenateDetail',
      method: 'POST',
      data
    })
  },
  // 议员申请
  getSenateApply (data) {
    return request({
      url: '/api/getSenateApply',
      method: 'POST',
      data
    })
  },
   // 议员申请
   memberSearch (data) {
    return request({
      url: '/api/memberSearch',
      method: 'POST',
      data
    })
  },
}