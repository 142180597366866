import request from '../request'
export default {
  // 用户注册
  userRegister (data) {
    return request({
      url: '/api/register',
      method: 'POST',
      data,
      encryptionType: 2
    })
  },
  // 注册成功未发送邮件重新发送邮件
  reactivate (data) {
    return request({
      url: '/api/reactivate',
      method: 'POST',
      data
    })
  },
  // 判断2fa_状态
  emailGet2faStatus (data) {
    return request({
      url: '/api/emailGet2faStatus',
      method: 'POST',
      data
    })
  },
  // 用户登录
  userLogin (data) {
    return request({
      url: '/api/login',
      method: 'POST',
      data,
      encryptionType: 1
    })
  },
  // 用户登录
  deleteAccount (data) {
    return request({
      url: '/api/deleteAccount',
      method: 'POST',
      data,
      encryptionType: 2
    })
  },
  // userLogin (data) {
  //   return request({
  //     url: '/api/getdecrypt',
  //     method: 'POST',
  //     data,
  //     encryptionType: 1
  //   })
  // },
  // 用户登录验证码
  captcha (data) {
    return request({
      url: '/api/captcha',
      method: 'POST',
      data
    })
  },
  // 验证登录
  login2Fa (data) {
    return request({
      url: '/api/login2fa',
      method: 'POST',
      data,
      encryptionType: 1
    })
  },
  // 获取登录问题
  getLoginProblem (data) {
    return request({
      url: '/api/getLoginProblem',
      method: 'POST',
      data
    })
  },
  // 密保问题登录(丢失手机)
  problemLogin (data) {
    return request({
      url: '/api/problemLogin',
      method: 'POST',
      data,
      encryptionType: 1
    })
  },
  // 忘记密码发送邮件
  forgotPassword (data) {
    return request({
      url: '/api/forgotPassword',
      method: 'POST',
      data
    })
  },
  // 忘记密码重置密码
  resetPassword (data) {
    return request({
      url: '/api/resetPassword',
      method: 'POST',
      data
    })
  },
  // 申请恢复冻结获取问题
  getUnfreezeProblem (data) {
    return request({
      url: '/api/getUnfreezeProblem',
      method: 'POST',
      data
    })
  },
  // 申请恢复冻结
  applyUnfreeze (data) {
    return request({
      url: '/api/applyUnfreeze',
      method: 'POST',
      data
    })
  },
  // 后台重置2fa
  reset2fa (data) {
    return request({
      url: '/api/reset2fa',
      method: 'POST',
      data
    })
  },

  // 退出登录
  signOut(data) {
    return request({
      url: '/api/signOut',
      method: 'POST',
      data
    })
  }
}
