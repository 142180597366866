
import Vue from 'vue'
import {
  MessageBox,
  Message,
  DatePicker,
  Table,
  TableColumn,
  Upload,
  Select,
  Option,
  Cascader,
  Tabs,
  TabPane,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Loading,
  Avatar,
  Button,
  Card,
  Row,
  Col,
  Image,
  Rate,
  Tooltip,
  Dialog,
  Pagination,
  Form,
  FormItem,
  Input,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Alert,
  Carousel,
  CarouselItem,
  Autocomplete,
  Breadcrumb,
  BreadcrumbItem,
  Switch,
  Popover,
  Tag,
  InfiniteScroll,
  Skeleton,
  SkeletonItem,
  Divider,
  InputNumber,
  Collapse,
  CollapseItem,
} from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css'

import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm

locale.use(lang)
Vue.use(Loading)
Vue.use(DatePicker)
Vue.use(Row)
Vue.use(Col)
Vue.use(Dialog)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Upload)
Vue.use(Select)
Vue.use(Option)
Vue.use(Cascader)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Avatar)
Vue.use(Button)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Image)
Vue.use(Rate)
Vue.use(Tooltip)
Vue.use(Dialog)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Alert)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Autocomplete)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Switch)
Vue.use(Popover)
Vue.use(Tag)
Vue.use(InfiniteScroll)
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Divider)
Vue.use(InputNumber)
Vue.use(Collapse)
Vue.use(CollapseItem)
