import request from '../request'
export default {
  // 获取Skill课程列表
  getSkillCourseList(params) {
    return request({
      url: '/api/getSkillCourseList',
      method: 'GET',
      params
    })
  },

  // 获取Skill课程详情
  getSkillCourseInfo(params) {
    return request({
      url: '/api/getSkillCourseInfo',
      method: 'GET',
      params
    })
  },

  // 获取Skill课程的培训列表
  getSkillCourseTraining(params) {
    return request({
      url: '/api/getSkillCourseTraining',
      method: 'GET',
      params
    })
  },

  // 获取培训详情
  getTrainingInfo(params) {
    return request({
      url: '/api/getTrainingInfo',
      params
    })
  },

  // 报名培训
  registerTraining(data) {
    return request({
      url: '/api/registerTraining',
      method: 'POST',
      data
    })
  },

  // 点击考试
  clickExam(params) {
    return request({
      url: '/api/clickExam',
      method: 'get',
      params
    })
  },

//选择语言获取试卷
  selectLanguageGetQuestionnaire(params) {
    return request({
      url: '/api/selectLanguageGetQuestionnaire',
      method: 'get',
      params
    })
  },
  
  // 提交问卷
  submitQuestionnaire(data) {
    return request({
      url: '/api/submitQuestionnaire',
      method: 'POST',
      data
    })
  },

  // 成为讲师
  applyTrainer(data) {
    return request({
      url: '/api/applyTrainer',
      method: 'POST',
      data
    })
  },

  //我的培训
  getMyTraining(params) {
    return request({
      url: '/api/getMyTraining',
      method: 'get',
      params
    })
  },

  //查看成为讲师进度
  checkApplyTrainer(params) {
    return request({
      url: '/api/checkApplyTrainer',
      method: 'get',
      params
    })
  },

  //讲师信息
  getTrainerInfo(params) {
    return request({
      url: '/api/getTrainerInfo',
      method: 'get',
      params
    })
  },

  //获取主题培训列表
  getTrainingThemeList(params) {
    return request({
      url: '/api/getTrainingThemeList',
      method: 'get',
      params
    })
  },

  //获取活动列表
  getEventList(params) {
    return request({
      url: '/api/getEventList',
      method: 'get',
      params
    })
  },

  //获取建议书列表
  getRequestList(params) {
    return request({
      url: '/api/getRequestList',
      method: 'get',
      params
    })
  },

  //获取完结课程和证书
  getCompletedCourse(params) {
    return request({
      url: '/api/getCompletedCourse',
      method: 'get',
      params
    })
  },

  //获取建议书详情
  getRequestInfo(params) {
    return request({
      url: '/api/getRequestInfo',
      method: 'get',
      params
    })
  },

  // 申请建议书
  applyRequest(data) {
    return request({
      url: '/api/applyRequest',
      method: 'POST',
      data
    })
  },

  // 取消报名
  cancelRegister(data) {
    return request({
      url: '/api/cancelRegister',
      method: 'POST',
      data
    })
  },

  // 编辑培训材料链接
  editTrainingMaterialLink(data) {
    return request({
      url: '/api/editTrainingMaterialLink',
      method: 'POST',
      data
    })
  },

}